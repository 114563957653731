function fetchOrganisationDetails(organisationName: string) {
  return new Promise<any>((resolve, reject) => {
    fetch(
      `https://organisations.primio.app/organisations/${organisationName}`,
      {
        headers: {
          Authentication: `Bearer ${process.env.REACT_APP_ORGANISATIONS_TOKEN}`,
        },
      }
    )
      .then((response) => resolve(response.json()))
      .catch((error) => {
        reject(error);
      });
  });
}

export default function getBaseUrl(environment: string) {
  return new Promise<string>((resolve, reject) => {
    if (environment.trim() === "") {
      reject();
    }

    if (environment === "local") {
      resolve(`http://localhost:3000`);
    }

    // do call for organisation details
    // retrieve base url from response
    fetchOrganisationDetails(environment).then((response) => {
      resolve(response.data.appApiUrl);
    });
  });
}
